exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-badge-js": () => import("./../../../src/pages/badge.js" /* webpackChunkName: "component---src-pages-badge-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-company-become-a-partner-js": () => import("./../../../src/pages/company/become-a-partner.js" /* webpackChunkName: "component---src-pages-company-become-a-partner-js" */),
  "component---src-pages-company-careers-apply-now-js": () => import("./../../../src/pages/company/careers-apply-now.js" /* webpackChunkName: "component---src-pages-company-careers-apply-now-js" */),
  "component---src-pages-company-careers-js": () => import("./../../../src/pages/company/careers.js" /* webpackChunkName: "component---src-pages-company-careers-js" */),
  "component---src-pages-company-grants-js": () => import("./../../../src/pages/company/grants.js" /* webpackChunkName: "component---src-pages-company-grants-js" */),
  "component---src-pages-company-history-js": () => import("./../../../src/pages/company/history.js" /* webpackChunkName: "component---src-pages-company-history-js" */),
  "component---src-pages-company-media-and-press-materials-js": () => import("./../../../src/pages/company/media-and-press-materials.js" /* webpackChunkName: "component---src-pages-company-media-and-press-materials-js" */),
  "component---src-pages-company-mission-js": () => import("./../../../src/pages/company/mission.js" /* webpackChunkName: "component---src-pages-company-mission-js" */),
  "component---src-pages-company-our-team-js": () => import("./../../../src/pages/company/our-team.js" /* webpackChunkName: "component---src-pages-company-our-team-js" */),
  "component---src-pages-company-partnership-contact-form-js": () => import("./../../../src/pages/company/partnership-contact-form.js" /* webpackChunkName: "component---src-pages-company-partnership-contact-form-js" */),
  "component---src-pages-company-roadmap-js": () => import("./../../../src/pages/company/roadmap.js" /* webpackChunkName: "component---src-pages-company-roadmap-js" */),
  "component---src-pages-contact-confirmation-js": () => import("./../../../src/pages/contact-confirmation.js" /* webpackChunkName: "component---src-pages-contact-confirmation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq/[...].js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-general-queries-js": () => import("./../../../src/pages/general-queries.js" /* webpackChunkName: "component---src-pages-general-queries-js" */),
  "component---src-pages-help-support-js": () => import("./../../../src/pages/help-support.js" /* webpackChunkName: "component---src-pages-help-support-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-pl-blog-js": () => import("./../../../src/pages/pl/blog.js" /* webpackChunkName: "component---src-pages-pl-blog-js" */),
  "component---src-pages-pl-cennik-js": () => import("./../../../src/pages/pl/cennik.js" /* webpackChunkName: "component---src-pages-pl-cennik-js" */),
  "component---src-pages-pl-certyfikat-js": () => import("./../../../src/pages/pl/certyfikat.js" /* webpackChunkName: "component---src-pages-pl-certyfikat-js" */),
  "component---src-pages-pl-dzial-sprzedazy-js": () => import("./../../../src/pages/pl/dzial-sprzedazy.js" /* webpackChunkName: "component---src-pages-pl-dzial-sprzedazy-js" */),
  "component---src-pages-pl-faq-js": () => import("./../../../src/pages/pl/faq/[...].js" /* webpackChunkName: "component---src-pages-pl-faq-js" */),
  "component---src-pages-pl-index-js": () => import("./../../../src/pages/pl/index.js" /* webpackChunkName: "component---src-pages-pl-index-js" */),
  "component---src-pages-pl-kontakt-js": () => import("./../../../src/pages/pl/kontakt.js" /* webpackChunkName: "component---src-pages-pl-kontakt-js" */),
  "component---src-pages-pl-o-nas-dla-mediow-js": () => import("./../../../src/pages/pl/o-nas/dla-mediow.js" /* webpackChunkName: "component---src-pages-pl-o-nas-dla-mediow-js" */),
  "component---src-pages-pl-o-nas-dotacje-js": () => import("./../../../src/pages/pl/o-nas/dotacje.js" /* webpackChunkName: "component---src-pages-pl-o-nas-dotacje-js" */),
  "component---src-pages-pl-o-nas-formularz-partnerski-js": () => import("./../../../src/pages/pl/o-nas/formularz-partnerski.js" /* webpackChunkName: "component---src-pages-pl-o-nas-formularz-partnerski-js" */),
  "component---src-pages-pl-o-nas-historia-js": () => import("./../../../src/pages/pl/o-nas/historia.js" /* webpackChunkName: "component---src-pages-pl-o-nas-historia-js" */),
  "component---src-pages-pl-o-nas-kariera-aplikuj-teraz-js": () => import("./../../../src/pages/pl/o-nas/kariera-aplikuj-teraz.js" /* webpackChunkName: "component---src-pages-pl-o-nas-kariera-aplikuj-teraz-js" */),
  "component---src-pages-pl-o-nas-kariera-js": () => import("./../../../src/pages/pl/o-nas/kariera.js" /* webpackChunkName: "component---src-pages-pl-o-nas-kariera-js" */),
  "component---src-pages-pl-o-nas-misja-js": () => import("./../../../src/pages/pl/o-nas/misja.js" /* webpackChunkName: "component---src-pages-pl-o-nas-misja-js" */),
  "component---src-pages-pl-o-nas-nasz-zespol-js": () => import("./../../../src/pages/pl/o-nas/nasz-zespol.js" /* webpackChunkName: "component---src-pages-pl-o-nas-nasz-zespol-js" */),
  "component---src-pages-pl-o-nas-plan-rozwoju-js": () => import("./../../../src/pages/pl/o-nas/plan-rozwoju.js" /* webpackChunkName: "component---src-pages-pl-o-nas-plan-rozwoju-js" */),
  "component---src-pages-pl-o-nas-zostan-partnerem-js": () => import("./../../../src/pages/pl/o-nas/zostan-partnerem.js" /* webpackChunkName: "component---src-pages-pl-o-nas-zostan-partnerem-js" */),
  "component---src-pages-pl-opinie-klientow-js": () => import("./../../../src/pages/pl/opinie-klientow.js" /* webpackChunkName: "component---src-pages-pl-opinie-klientow-js" */),
  "component---src-pages-pl-polityka-cookies-js": () => import("./../../../src/pages/pl/polityka-cookies.js" /* webpackChunkName: "component---src-pages-pl-polityka-cookies-js" */),
  "component---src-pages-pl-polityka-prywatnosci-js": () => import("./../../../src/pages/pl/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-pl-polityka-prywatnosci-js" */),
  "component---src-pages-pl-polityka-zwrotow-js": () => import("./../../../src/pages/pl/polityka-zwrotow.js" /* webpackChunkName: "component---src-pages-pl-polityka-zwrotow-js" */),
  "component---src-pages-pl-potwierdzenie-kontaktu-js": () => import("./../../../src/pages/pl/potwierdzenie-kontaktu.js" /* webpackChunkName: "component---src-pages-pl-potwierdzenie-kontaktu-js" */),
  "component---src-pages-pl-promocja-js": () => import("./../../../src/pages/pl/promocja.js" /* webpackChunkName: "component---src-pages-pl-promocja-js" */),
  "component---src-pages-pl-warunki-korzystania-js": () => import("./../../../src/pages/pl/warunki-korzystania.js" /* webpackChunkName: "component---src-pages-pl-warunki-korzystania-js" */),
  "component---src-pages-pl-wsparcie-techniczne-js": () => import("./../../../src/pages/pl/wsparcie-techniczne.js" /* webpackChunkName: "component---src-pages-pl-wsparcie-techniczne-js" */),
  "component---src-pages-pl-zapytania-ogolne-js": () => import("./../../../src/pages/pl/zapytania-ogolne.js" /* webpackChunkName: "component---src-pages-pl-zapytania-ogolne-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-promo-code-js": () => import("./../../../src/pages/promo-code.js" /* webpackChunkName: "component---src-pages-promo-code-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund-policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-sales-js": () => import("./../../../src/pages/sales.js" /* webpackChunkName: "component---src-pages-sales-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

